<template>
    <div class="aboutWrap ">
       <div class="content ">
            <div class="title">About</div>
           <div class="top">
               <p >Open MetaNet Foundation (OMF) is a non-profit organization dedicated to supporting MetaNet and related technologies.</p>
           </div>
           <div class="center ">
               <p> OMF is not a company or even a traditional non-profit organization.
                    We are an organization that supports the opening and development of MetaNet.
                    We are part of the large ecosystem that supports MetaNet. 
                    Our mission is to do the best for the openness and success of MetaNet.</p>
           </div>
           <div class="bottom">
                <p>  Our goal is to provide support and help for the projects in need,
                to become a valuable voice in the MetaNet state system, 
                and to transmit the value of MetaNet to the outside world.</p>
           </div>
       </div>
        <div class="footer">
        <div class="footerLeft">
          <div @click.stop="toMetaID">MetaID</div>
          <div @click.stop="toMetaProtocols">MetaProtocols</div>
          <div><a target="_blank" href="mailto:showpay@showpay.io">Contact us: metaid@metaid.io</a></div>
        </div>
        <div class="footerRight">
          <div >© 2021 Open MetaNet Foundation, All Rights Reserved.</div>
        </div>
        </div> 
    </div>
</template>
<script>
export default {
    name: 'About',
    data(){
        return{
            
        }
    }
}
</script>
<style lang="scss" scoped>
.aboutWrap{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80vw;
    padding-left: 8rem /* 112/14 */;
    padding-right: 8rem /* 112/14 */;
  
    .content{
        padding-top: 7.857143rem /* 110/14 */;
        .title{
        font-family:PingFangSC-Semibold, sans-serif;
        padding-top: 3.571429rem /* 50/14 */;
        font-size: 2.571429rem /* 36/14 */;
        font-weight: Medium;
        }
        div{
            padding-top: 2.142857rem /* 30/14 */
        }
        p{
            font-family:PingFangSC-Regular, sans-serif;
            font-size: 1.714286rem /* 24/14 */;
            line-height: 1.5em;
        }
    }
    .footer{
    // height: 5%;
    width: 85vw;
    height: auto;
    // height: 5rem /* 70/14 */;
    line-height: 1.5em;
    display: flex;
    flex-direction: row;
    margin-bottom: .714286rem /* 10/14 */;
    justify-content: space-between;
    align-items: center;
    // padding-left: 8rem /* 112/14 */;
    font-size: 1rem /* 14/14 */;
    font-family: PingFangSC-Regular, sans-serif;
    color: #101315;
    opacity: 0.4;
    filter:alpha(opacity=40);
    -moz-opacity:0.4;
    -khtml-opacity:0.4;
    .footerLeft{
      width: 30vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
      justify-content: space-between;
      div{
        
        cursor: pointer;
        &:hover{
          color:#3182F9;
           opacity: 1;
          filter:alpha(opacity=100);
          -moz-opacity:1;
          -khtml-opacity:1;
        }
        
      }
      div:nth-of-type(3){
        >a{
          &:hover{
              color:#3182F9;
               opacity: 1;
          filter:alpha(opacity=100);
          -moz-opacity:1;
          -khtml-opacity:1;
          }
        }
      }
    }
    .footerRight{
    
      cursor: pointer;
    }

  }
}
@media screen and (max-width:768px){
    .aboutWrap{
    width: 80vw;
    padding-left: 2.142857rem /* 30/14 */;
    padding-right: 8rem /* 112/14 */;
    .footer{
        margin-top: 3.571429rem /* 50/14 */;
        margin-left: .714286rem /* 10/14 */;
    }
}
}
@media screen and (max-width:500px){
 .aboutWrap{
     width: 100vw;
        padding-left:0;
        padding-right: 0;
    // height: 100vh;
    height: auto;
    .footer{
        // margin-top:10.714286rem /* 150/14 */;
    line-height:1.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100vw;
    font-size: .928571rem /* 13/14 */;
    font-family: PingFangSC-Regular, sans-serif;
    color: #101315;
    opacity: 0.4;
    filter:alpha(opacity=40);
    -moz-opacity:0.4;
    -khtml-opacity:0.4;
    .footerLeft{
      display: flex;
      flex-direction: row;
    //   flex-wrap: wrap;
    //   justify-content: flex-start;
    }
    .footerRight{
      // padding: 0px;
      cursor: pointer;
      div{
          font-size: .928571rem /* 13/14 */;
      }
    }
    }
   
    
    .content{
      .title{
       padding-top:0;
        font-size: 2.142857rem /* 30/14 */;
        font-weight: Medium;
        }
        padding-left: .714286rem /* 10/14 */;
        max-width: 95vw;
        div{
            padding-top: 2.142857rem /* 30/14 */
        }
        p{
            font-weight: Regular;
            font-size: 1.285714rem /* 18/14 */;
            line-height: 1.5em;
        }
    }
}
}
</style>